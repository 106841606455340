import Axios from "axios";
import { formatInTimeZone } from "date-fns-tz";
import { useEffect, useState } from "react";
import mock from "./events-mock.json";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import logo from "./logo.svg";

const EventHeader = styled.header`
  text-align: center;
  h3 {
    font-family: "Berlingske", serif;
    font-size: 42px;
    margin: 0;
  }
  a {
    display: inline-block;
    margin-top: 10px;
    font-size: 18px;
    color: #25596d;
    text-decoration: underline;
  }
`;

const EventButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px;
  button {
    appearance: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    background: none;
    padding: 10px;
    color: inherit;
    border-radius: 5px;
    &.active {
      background-color: #25596d;
      color: white;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

const EventList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px 20px;
  margin: 30px 0px;
  padding: 30px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Event = styled.a`
  display: flex;
  text-decoration: none!important;
  h5 {
    font-family: "Berlingske", serif;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }
  h6 {
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    margin: 5px 0 10px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    font-size: 16px;
  }
  ul, ol {
    padding: 0;
  }
`;

const DayBlock = styled.div`
  background-color: #173e34;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: auto;
  text-align: Center;
  text-transform: uppercase;
  padding: 10px;
  line-height: 100%;
  margin-right: 10px;
  strong {
    font-weight: bold;
    font-size: 12px;
  }
`;

const EventNavigation = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  button {
    appearance: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    background: none;
    padding: 0;
    margin: 0 10px;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
    &:disabled {
      color: #999;
    }
  }
`;

function App() {
  const [data, setData] = useState(null);
  const [campus, setCampus] = useState("both");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const formatDateTime = (
    date,
    timeZone = "America/Los_Angeles",
    format = "MMMM d, yyyy h:mm a"
  ) => {
    return formatInTimeZone(date, timeZone, format);
  };

  const prettyStartDate = (event) => {
    return {
      month: formatDateTime(event.start_date, "America/Los_Angeles", "MMM"),
      day: formatDateTime(event.start_date, "America/Los_Angeles", "d"),
    };
  };

  const prettyDate = (event) => {
    var dateString = "";
    const startD = formatDateTime(
      event.start_date,
      "America/Los_Angeles",
      "MMMM d"
    );
    const endD = formatDateTime(
      event.end_date,
      "America/Los_Angeles",
      "MMMM d"
    );

    const allDay = event.all_day;

    if (allDay) {
      if (startD === endD) {
        dateString += startD;
      } else {
        dateString += startD + " - " + endD;
      }
      dateString += "<br /> All Day";
    } else {
      if (startD === endD) {
        dateString += formatDateTime(
          event.start_date,
          "America/Los_Angeles",
          "MMMM d"
        );
        dateString += "<br />";
        dateString += formatDateTime(
          event.start_date,
          "America/Los_Angeles",
          "h:mm a"
        );
        dateString += " - ";
        dateString += formatDateTime(
          event.end_date,
          "America/Los_Angeles",
          "h:mm a"
        );
      } else {
        dateString += formatDateTime(
          event.start_date,
          "America/Los_Angeles",
          "MMMM d, h:mm a"
        );
        dateString += " - ";
        dateString += formatDateTime(
          event.end_date,
          "America/Los_Angeles",
          "MMMM d, h:mm a"
        );
      }
    }

    return dateString;
  };

  useEffect(() => {
    var params = {
      per_page: 9,
      page: page,
    };

    var cat = [];

    if (campus === "pebble") {
      cat.push(66);
    } else if (campus === "carmel") {
      cat.push(67);
    }

    if (cat.length) {
      params.categories = cat;
    }

    Axios.get("https://stevensonschool.org/wp-json/tribe/events/v1/events", {
      params: params,
    }).then((response) => {
      setData(response.data);
      setTotalPages(response.data.total_pages);
    });
  }, [page, campus]);

  useEffect(() => {
    setPage(1);
  }, [campus]);

  return (
    <div>
      {data ? (
        <>
          <EventHeader>
            <h3>Upcoming Events</h3>
            <a href="https://stevensonschool.org/calendar/">View All Events</a>
          </EventHeader>
          <EventButtons>
            <button
              className={campus === "both" ? "active" : ""}
              aria-pressed={campus === "both" ? "true" : "false"}
              onClick={() => setCampus("both")}
            >
              Both Campuses
            </button>
            <button
              className={campus === "pebble" ? "active" : ""}
              aria-pressed={campus === "pebble" ? "true" : "false"}
              onClick={() => setCampus("pebble")}
            >
              Pebble Beach Campus
            </button>
            <button
              className={campus === "carmel" ? "active" : ""}
              aria-pressed={campus === "carmel" ? "true" : "false"}
              onClick={() => setCampus("carmel")}
            >
              Carmel Campus
            </button>
          </EventButtons>
          <EventList>
            {data.events.map((event) => (
              <Event href={event.url} target="_blank" key={event.id}>
                <DayBlock>
                  <span>
                    <strong>
                      {prettyStartDate(event).month}
                    </strong>
                  </span>
                  <span>{prettyStartDate(event).day}</span>
                </DayBlock>
                <div className="event-content">
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: event.title,
                    }}
                  ></h5>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: prettyDate(event),
                    }}
                  ></h6>
                  {/* {event.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: event.description,
                      }}
                    ></div>
                  )} */}
                </div>
              </Event>
            ))}
          </EventList>
          <EventNavigation>
            <button disabled={page === 1} onClick={() => setPage(page - 1)}>
              ‹ Previous
            </button>
            <button disabled={page === totalPages} onClick={() => setPage(page + 1)}>Next ›</button>
          </EventNavigation>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;
